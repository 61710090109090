<template>
  <Modal :show="showModal" @onClose="closeModal">
    <template #header>{{
      selectedDocType
        ? `${$t("edit")} ${selectedDocType.name}`
        : $t("add-document-type")
    }}</template>
    <template #body
      ><doc-type-form @onClose="closeModal" :doctype="selectedDocType"
    /></template>
  </Modal>

  <Modal :show="showUserModal" @onClose="closeUserModal">
    <template #header>{{ $t("assign-user") }}</template>
    <template #body
      ><assign-type-user-form @onClose="closeUserModal"
    /></template>
  </Modal>

  <!-- show only if loading from server not from cache -->
  <Loader v-if="loading && list.length === 0" />
  <div v-else>
    <section class="max-w-screen-2xl mx-auto pt-6 px-2 sm:px-6 lg:px-8">
      <div class="flex flex-row items-center justify-start">
        <div class="flex items-center">
          <div class="mr-2">
            <img src="/icon-title-documents.svg" class="h-5 w-5" />
          </div>
          <div>
            <h1 class="font-bold text-WADARKBLUE-500 text-base uppercase">
              {{ $t("document-types") }}
            </h1>
          </div>
        </div>
      </div>
    </section>
    <section class="max-w-screen-2xl mx-auto px-2 py-6 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <button
            @click="openModal"
            v-if="isAdminOrEdit"
            class="
              bg-WAORANGE-500
              hover:bg-WAORANGE-400
              mr-2
              px-4
              py-2
              rounded-lg
              uppercase
              text-white text-xs
            "
          >
            + {{ $t("add-document-type") }}
          </button>
          <button
            v-if="isAdminOrEdit"
            @click="openUserModal"
            class="
              border border-WAORANGE-500
              hover:bg-white
              mr-2
              px-4
              py-2
              rounded-lg
              text-xs
              uppercase
            "
          >
            {{ $t("assign-user") }}
          </button>
        </div>
        <doc-type-filter />
      </div>
    </section>
    <section class="lg:px-8 max-w-screen-2xl mx-auto px-2 sm:px-6">
      <doc-type-table-row></doc-type-table-row>
      <div v-if="error" class="py-3 px-5 mb-3 rounded bg-red-50 text-red-400">
        <span>* {{ error }}</span>
      </div>
      <div v-if="filteredDocTypes.length > 0">
        <doc-type
          v-for="doctype in filteredDocTypes"
          :key="doctype.id"
          :doctype="doctype"
          @edit="editDocType"
        />
      </div>
      <div v-else>
        <h1 class="text-sm text-center text-WADARKBLUE-500 my-10">
          {{ $t("no-data") }}
        </h1>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import DocType from "@/components/documents/DocType.vue";
import DocTypeFilter from "@/components/documents/DocTypeFilter.vue";
import DocTypeForm from "@/components/documents/DocTypeForm.vue";
import DocTypeTableRow from "@/components/documents/DocTypeTableRow.vue";
import AssignTypeUserForm from "@/components/documents/AssignTypeUserForm.vue";

import Loader from "@/components/Loader.vue";
import Modal from "@/components/Modal.vue";

export default {
  components: {
    DocType,
    Loader,
    DocTypeFilter,
    DocTypeForm,
    Modal,
    AssignTypeUserForm,
    DocTypeTableRow,
  },
  computed: {
    ...mapGetters(["currentUser"]),
    ...mapGetters("doctypes", ["loading", "error", "list", "filteredDocTypes"]),

    isAdminOrEdit() {
      return (
        this.currentUser &&
        (this.currentUser?.role?.document?.admin ||
          this.currentUser?.role?.document?.edit ||
          this.currentUser?.isSuper)
      );
    },
  },

  async created() {
    await this.getDocTypes();
  },

  data() {
    return {
      showModal: false,
      showUserModal: false,
      selectedDocType: null,
    };
  },

  methods: {
    ...mapActions("doctypes", ["getDocTypes"]),
    openModal() {
      this.showModal = true;
      this.selectedDocType = null;
    },

    closeModal() {
      this.showModal = false;
      this.selectedDocType = null;
    },

    openUserModal() {
      this.showUserModal = true;
    },

    closeUserModal() {
      this.showUserModal = false;
    },

    editDocType(doctype) {
      this.selectedDocType = doctype;
      this.showModal = true;
    },
  },
};
</script>
