<template>
  <button
    class="block w-full cursor-default bg-white max-w-screen-2xl mb-1 mt-0 mx-auto p-4 rounded-lg shadow-sm shadow-gray-100 lg:px-4"
  >
    <div class="flex items-center justify-between">
      <div class="flex flex-row">
        <div class="flex pr-1 w-44">
          <p class="text-WADARKBLUE-500 text-sm font-thin truncate">
            {{ doctype?.name }}
          </p>
        </div>
        <div class="hidden px-1 sm:flex w-36 md:w-96">
          <p class="text-WADARKBLUE-500 text-sm font-thin text-left">
            {{ getDocTypeUsers(doctype?.users) }}
          </p>
        </div>
      </div>

      <div class="flex">
        <div class="flex relative justify-end w-20">
          <button
            @click="openStatusModal(doctype)"
            class="flex font-thin text-sm items-center"
            :class="{
              'text-WAGREEN-500': doctype.status === 'Active',
              'text-WAORANGE-500': doctype.status === 'Disabled',
            }"
            :disabled="!isAdmin"
          >
            {{ doctype.status }}
            <img
              v-if="isAdmin"
              class="w-2 ml-1"
              src="/chevron-down-dark.svg"
              alt="chevron"
            />
          </button>

          <status-modal
            v-if="showStatus.status && showStatus.id === doctype._id"
            v-model="status"
            :data="statusData"
            @close="closeStatusModal"
          ></status-modal>
        </div>
        <div class="relative flex justify-end w-20">
          <option-dropdown-new
            @btnClick="openOptionDropDown(doctype._id)"
            @onClose="closeOptionDropdown"
            :show="
              showOptionDropdown.status && showOptionDropdown.id === doctype._id
            "
          >
            <button
              @click.prevent="$emit('edit', doctype)"
              class="px-3 py-2 flex justify-between items-center text-sm w-full text-gray-700 hover:bg-WALIGHTBLUE-50"
            >
              {{ $t("edit") }}
            </button>

            <button
              @click.prevent="openConfirmModal(doctype._id)"
              class="px-3 py-2 flex justify-between items-center w-full text-red-700 text-sm hover:bg-red-500 hover:text-white"
            >
              {{ $t("delete") }}
              <!-- <img src="/copy.svg" alt="" /> -->
            </button>
          </option-dropdown-new>

          <div
            class="absolute -bottom-16 w-80"
            v-if="showConfirm.status && showConfirm.id === doctype._id"
          >
            <confirm-modal
              :show="showConfirm.status && showConfirm.id === doctype._id"
              @no="closeConfirmModal"
              @yes="confirmDelete(doctype._id)"
            ></confirm-modal>
          </div>
        </div>
      </div>
    </div>
  </button>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import StatusModal from "../StatusModal.vue";
import ConfirmModal from "../ConfirmModal.vue";
import OptionDropdownNew from "../shared/option-dropdown/OptionDropdownNew.vue";

export default {
  computed: {
    ...mapGetters(["currentUser"]),

    isAdminOrEdit() {
      return (
        this.currentUser &&
        (this.currentUser?.role?.document?.admin ||
          this.currentUser?.role?.document?.edit)
      );
    },

    isAdmin() {
      return (
        this.currentUser &&
        (this.currentUser?.role?.document?.admin || !!this.currentUser?.isSuper)
      );
    },
  },

  components: { StatusModal, ConfirmModal, OptionDropdownNew },
  props: ["doctype"],
  emits: ["edit"],
  data() {
    return {
      statusData: [
        { id: 1, value: "Active" },
        { id: 2, value: "Disabled" },
      ],
      status: "",
      showStatus: { status: false, id: "" },
      showOptionDropdown: { status: false, id: "" },
      showConfirm: { status: false, id: "" },
    };
  },
  methods: {
    ...mapActions("doctypes", ["changeStatus", "deleteDocType"]),
    getDocTypeUsers(users) {
      const names = users.map((user) => `${user.name}`).join(", ");
      return users.length > 0 ? names : " ";
    },

    openStatusModal(document) {
      this.showStatus.id = document._id;
      this.showStatus.status = true;
    },

    closeStatusModal(e) {
      if (!e) {
        this.showStatus.status = false;
        this.showStatus.id = "";
      } else if (!this.$el.contains(e.target)) {
        this.showStatus.status = false;
        this.showStatus.id = "";
      }
    },

    openOptionDropDown(id) {
      if (this.showStatus.status) {
        this.showStatus.status = false;
        this.showStatus.id = "";
      }

      this.showOptionDropdown.status = true;
      this.showOptionDropdown.id = id;
    },

    closeOptionDropdown(e) {
      if (!e) {
        this.showOptionDropdown.status = false;
        this.showOptionDropdown.id = "";
      } else if (!this.$el.contains(e.target)) {
        this.showOptionDropdown.status = false;
        this.showOptionDropdown.id = "";
      }
    },

    confirmDelete(id) {
      this.closeConfirmModal();
      this.deleteDocType(id);
    },

    openConfirmModal(id) {
      this.showConfirm.status = true;
      this.showConfirm.id = id;
    },

    closeConfirmModal(e) {
      if (!e) {
        this.showConfirm.status = false;
        this.showConfirm.id = "";
      } else if (!this.$el.contains(e.target)) {
        this.showConfirm.status = false;
        this.showConfirm.id = "";
      }
    },
  },

  mounted() {
    document.addEventListener("click", this.closeStatusModal);
  },

  beforeUnmount() {
    document.removeEventListener("click", this.closeStatusModal);
  },

  watch: {
    async status(status) {
      await this.changeStatus({ status, id: this.doctype._id });
    },
  },
};
</script>
