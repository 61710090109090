<template>
  <form @submit.prevent="onSubmit">
    <div v-if="error" class="py-3 px-5 mb-3 rounded bg-red-50 text-red-400">
      <span>* {{ error }}</span>
    </div>
    <div class="mb-4">
      <input
        class="rounded w-full"
        :placeholder="$t('document-type-name')"
        type="text"
        v-model="name"
      />
    </div>
    <div class="mb-4" v-if="doctype">
      <SelectInput
        :placeholder="$t('status')"
        :data="statusData"
        v-model="status"
        size="full"
      />
    </div>
    <div class="flex relative items-center justify-end pt-2 gap-x-3">
      <confirm-modal
        :show="showConfirm"
        @no="closeConfirmModal"
        @yes="confirmed(doctype._id)"
      ></confirm-modal>

      <button
        :disabled="loading || deleting"
        @click="openConfirmModal"
        type="button"
        v-if="doctype"
        class="
          rounded
          hover:text-white
          text-red-600
          py-1.5
          px-4
          hover:bg-red-500
          text-sm
          font-semibold
        "
      >
        {{ deleting ? $t("deleting") : $t("delete") }}
      </button>
      <button
        :disabled="loading || deleting"
        class="rounded text-white py-1.5 px-4 bg-WAORANGE-500 text-sm"
      >
        {{
          this.doctype
            ? loading
              ? $t("editing")
              : $t("edit")
            : loading
            ? $t("adding")
            : $t("add-document-type")
        }}
      </button>
    </div>
  </form>
</template>

<script>
import { mapMutations } from "vuex";
import SelectInput from "../forms/SelectInput.vue";
import httpClient from "../../services/httpClient";
import ConfirmModal from "@/components/ConfirmModal.vue";

export default {
  components: { SelectInput, ConfirmModal },
  data() {
    return {
      loading: false,
      deleting: false,
      showConfirm: false,
      error: null,
      name: this.doctype ? this.doctype.name : "",
      status: this.doctype?.status,
      statusData: [
        { id: 1, value: "Active" },
        { id: 2, value: "Disabled" },
      ],
    };
  },
  emits: ["onClose"],
  methods: {
    ...mapMutations("doctypes", [
      "addDocType",
      "updateDocType",
      "removeDocType",
    ]),
    async onSubmit() {
      if (this.error) this.error = null;
      if (!this.name) return (this.error = "Please fill in all fields");

      try {
        this.loading = true;
        if (this.doctype) {
          if (!this.status) {
            this.loading = false;
            this.error = "Please add status";
            return;
          }

          const { data } = await httpClient.put(
            `/doctypes/${this.doctype._id}`,
            {
              name: this.name,
              status: this.status,
            }
          );

          this.updateDocType(data);
        } else {
          const { data } = await httpClient.post(`/doctypes`, {
            name: this.name,
          });

          this.addDocType(data);
        }

        this.loading = false;
        this.$emit("onClose");
      } catch (err) {
        this.loading = false;
        this.error =
          err.response && err.response.data.error
            ? err.response.data.error
            : err.error || err.message;
      }
    },

    async deleteDoctype(id) {
      if (this.error) this.error = null;

      try {
        this.deleting = true;

        await httpClient.delete(`/doctypes/${this.doctype._id}`);

        this.deleting = false;
        this.removeDocType(id);
        this.$emit("onClose");
      } catch (err) {
        this.deleting = false;
        this.error =
          err.response && err.response.data.error
            ? err.response.data.error
            : err.error || err.message;
      }
    },

    confirmed(id) {
      this.closeConfirmModal();
      this.deleteDoctype(id);
    },

    openConfirmModal() {
      this.showConfirm = true;
    },

    closeConfirmModal() {
      this.showConfirm = false;
    },
  },
  props: ["doctype"],
};
</script>

<style scoped>
input {
  border: none;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

form {
  width: 80vw;
}

@media screen and (min-width: 640px) {
  form {
    width: 400px;
  }
}
</style>
